import React from 'react';
import PropTypes from 'prop-types';

export const Logo = ({ color, className, strokeWidth }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <polygon
      fill={color}
      points="0 60 17.8179191 59.9653484 28.3309249 42.1387283 35.3757225 42.1387283 36.605932 40.1445087 35.3757225 38.7041806 24.8237775 38.7041806 25.2715203 39.5593591 15.3042795 56.5317919 5.72254335 56.5317919 19.7634427 28.4926976 23.0256156 35.1142595 25.2715203 36.1429679 26.0932169 33.559865 21.696902 24.6511277 30 7.97687861 54.2774566 56.5317919 37.7317375 56.5317919 32.229094 45.8032712 30 44.9445971 29.1057517 47.1173717 35.7378979 59.9653484 60 59.9653484 30 0"
    />
  </svg>
);

Logo.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  strokeWidth: PropTypes.string,
};
